<template>
  <div class="footer" :style="{ backgroundImage: bg }">
    <div class="wrapper">
      <div class="links animate__animated">
        <ul class="links-list">
          <li v-for="(link, index) in linkList" :key="index">
            <h3>{{ link.title }}</h3>
            <ul>
              <li v-for="(item, index) in link.itemList" :key="index">
                <a
                  v-if="item.url.indexOf('http') != -1"
                  :href="item.url"
                  target="_blank"
                >
                  {{ item.text }}
                </a>
                <router-link v-else :to="item.url">
                  {{ item.text }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="qrcode animate__animated">
        <ul class="qrcode-list">
          <li>
            <img src="../assets/images/qrcode.png" />
            <p>微信公众号</p>
          </li>
        </ul>
      </div>
      <div class="copyright animate__animated">
        <p>
          <router-link to="/pages/lxwm?type=lxwm">联系我们</router-link> |
          <router-link to="/pages/wzsm?type=wzsm">网站声明</router-link> |
          <router-link to="/pages/cjwt?type=cjwt">常见问题</router-link> |
          <router-link to="/pages/lyb?type=lyb">留言板</router-link>
        </p>
        <p>
          公司地址：吉林省长春市汽车开发区凯达北街1000号&emsp;联系电话：0431-85810056
        </p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >吉ICP备2021001444号-1</a
          >
          &copy; 长春凯达发展有限公司
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      linkList: [
        {
          title: "走进凯达",
          itemList: [
            { text: "企业简介", url: "/about/qyjj?type=qyjj" },
            { text: "董事长致辞", url: "/about/dszzc?type=dszzc" },
            { text: "组织结构", url: "/about/zzjg?type=zzjg" },
            { text: "企业文化", url: "/about/qywh?type=qywh" },
          ],
        },
        {
          title: "凯达资讯",
          itemList: [
            { text: "环球看点", url: "/list?type=86GiJyxgPxzp4p7xZ" },
            { text: "聚焦汽开", url: "/list?type=m6ZYzvEfAyAfmk8aL" },
            { text: "凯达动态", url: "/list?type=hkng3zuaD6tgRh8wi" },
            { text: "视频资讯", url: "/list?type=d9myMuK84ALafhXYd" },
            { text: "公告信息", url: "/list?type=uRLG5PtCsXhyDq5tX" },
          ],
        },
        {
          title: "凯达建设",
          itemList: [
            { text: "地产开发", url: "/list?type=BdrQLqSNpbukcksXd" },
            { text: "工业厂房", url: "/list?type=uKm6eDiJoYrAZPGxD" },
            { text: "科技创新", url: "/list?type=FA8Xojtg4yTxsd7iN" },
            { text: "水利生态", url: "/list?type=n84tasiTD7tZQBhyo" },
            { text: "教育文旅", url: "/list?type=aGfjy38WcauxhSZiZ" },
            { text: "市政公用", url: "/list?type=LSMZ4ZP532ofzGhLN" },
            { text: "景观绿化", url: "/list?type=uSjku6koPfkKKHqAg" },
            { text: "其他项目", url: "/list?type=APpWvgSZmTHK7jre2" },
          ],
        },
        {
          title: "凯达招商",
          itemList: [
            { text: "新能源汽车产业园", url: "/list?type=8h9f8jod6TmGv7avi" },
            { text: "新技术产业园", url: "/list?type=bP4xpgw5GvN5Li8uB" },
            { text: "青年人才服务园", url: "/list?type=KYeQPFqhKjTJZFqgG" },
            { text: "人力资源产业园", url: "/list?type=gJywirRF6jgZBFMgc" },
            { text: "了解更多", url: "/list?type=XKWc77iMypXKFhgMg" },
          ],
        },
        {
          title: "凯达招聘",
          itemList: [
            { text: "人才理念", url: "/recruit/rcln?type=rcln" },
            { text: "加入凯达", url: "/recruit/jrkd?type=jrkd" },
            { text: "创新凯达", url: "/recruit/cxkd?type=cxkd" },
            { text: "成长凯达", url: "/recruit/czkd?type=czkd" },
            { text: "幸福凯达", url: "/recruit/xfkd?type=xfkd" },
          ],
        },
        {
          title: "党建工作",
          itemList: [
            { text: "党建动态", url: "/list?type=YcY7PMyvthmQndhZY" },
            { text: "反腐倡廉", url: "/list?type=sXMW3G43NR6ZchSFL" },
            { text: "专题活动", url: "/list?type=6tsXCPh3t3r7i8KyK" },
          ],
        },
        {
          title: "凯达云",
          itemList: [
            {
              text: "项目管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "合同管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "档案管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "印信管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "人事管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "会议管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "凯达云旅",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "企业钱包",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
          ],
        },
        {
          title: "友情链接",
          itemList: [
            { text: "政府网站", url: "/links?type=hKk9rEqQFtNBxaZG8" },
            { text: "党建人社", url: "/links?type=rcwkwWN3q9zcEbTHk" },
            { text: "平台企业", url: "/links?type=Xzbq5SeJrnyd9zDhq" },
            { text: "建工企业", url: "/links?type=bhmaeNxJkccYvPLAs" },
            { text: "财经金融", url: "/links?type=h5KWSqALLfJcj6Shu" },
            { text: "咨询机构", url: "/links?type=PQM7LsbN8yuaD5PAn" },
            { text: "合作单位", url: "/links?type=wJcqjd54mDnBGRK5g" },
            { text: "合作高校", url: "/links?type=K5KA3fFujFGMiF8ds" },
          ],
        },
      ],
    };
  },
  props: {
    bg: { type: String },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.footer {
  background: url(../assets/images/bg6.jpg) no-repeat;
  color: #000;
  text-align: center;
  padding: 40px 0;
  .links {
    margin-bottom: 40px;
    .links-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 14px;
      line-height: 28px;
      text-align: left;
      h3 {
        font-size: 22px;
        color: #000;
        font-weight: normal;
        line-height: 40px;
        margin-bottom: 10px;
      }
    }
  }
  .qrcode {
    margin-bottom: 40px;
    .qrcode-list {
      display: flex;
      flex-direction: row;
      justify-content: center;
      li {
        margin: 0 20px;
        img {
          background: #fff;
          width: 120px;
          height: 120px;
          margin-bottom: 10px;
          padding: 8px;
          border-radius: 8px;
        }
      }
      li:hover {
        img {
          background: url(../assets/images/qrcode-hover.png) no-repeat 50% 50%;
          background-size: cover;
        }
      }
    }
  }
  .copyright {
    line-height: 30px;
  }
}
</style>
