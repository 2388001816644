import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import "./assets/css/reset.css";
import "./assets/iconfont/iconfont.css";
import "animate.css";
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
// import "./fullpage.scrollHorizontally.min"; // Optional. When using fullpage extensions
import VueFullPage from "vue-fullpage.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import loadLanguage from "./assets/js/language";

Vue.use(VueFullPage);
Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.$loadLanguage = loadLanguage;

// 百度统计
var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window全局变量
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?8bb9f20ee041c79c7c030dab9a8ff4e3";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

router.beforeEach((to, from, next) => {
  loadLanguage(localStorage.getItem("lang"));
  if (to.meta.title) {
    document.title = to.meta.title + "长春凯达";
  }
  if (_hmt) {
    if (to.path) {
      _hmt.push(["_trackPageview", to.fullPath]);
    }
  }
  next();
});

Vue.mixin({
  mounted() {
    loadLanguage();
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
