<template>
  <div class="menu">
    <ul class="link-menu">
      <li @click="switchLanguage()">{{ language }}</li>
      <li>
        <a
          href="https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard"
          target="_blank"
          >数字工作台</a
        >
      </li>
      <li>
        <a href="http://mail.cckaida.cn/" target="_blank">企业邮箱</a>
      </li>
    </ul>
    <div class="drawer-show iconfont icon-menu" @click="drawerShow()"></div>
    <transition
      enter-active-class="animate__animated animate__fadeInRight animate__faster"
      leave-active-class="animate__animated animate__fadeOutRight animate__faster"
    >
      <div class="drawer" v-show="show">
        <ul class="drawer-menu">
          <li
            v-for="(menu, index) in menuList"
            :key="index"
            @mouseenter="submenuShow(index)"
          >
            <span>{{ menu.title }}</span>
            <ul class="drawer-submenu" v-show="index === isShow">
              <li
                v-for="(item, index) in menu.itemList"
                :key="index"
                @click="drawerHidden()"
              >
                <a
                  v-if="item.url.indexOf('http') != -1"
                  :href="item.url"
                  target="_blank"
                >
                  {{ item.text }}
                </a>
                <router-link v-else :to="item.url">
                  {{ item.text }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <div
          class="drawer-hidden iconfont icon-close"
          @click="drawerHidden()"
        ></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      show: false,
      isShow: 0,
      menuList: [
        {
          title: "走进凯达",
          itemList: [
            { text: "企业简介", url: "/about/qyjj?type=qyjj" },
            { text: "董事长致辞", url: "/about/dszzc?type=dszzc" },
            { text: "组织结构", url: "/about/zzjg?type=zzjg" },
            { text: "企业文化", url: "/about/qywh?type=qywh" },
          ],
        },
        {
          title: "凯达资讯",
          itemList: [
            { text: "环球看点", url: "/list?type=86GiJyxgPxzp4p7xZ" },
            { text: "聚焦汽开", url: "/list?type=m6ZYzvEfAyAfmk8aL" },
            { text: "凯达动态", url: "/list?type=hkng3zuaD6tgRh8wi" },
            { text: "视频资讯", url: "/list?type=d9myMuK84ALafhXYd" },
            { text: "公告信息", url: "/list?type=uRLG5PtCsXhyDq5tX" },
          ],
        },
        {
          title: "凯达建设",
          itemList: [
            { text: "地产开发", url: "/list?type=BdrQLqSNpbukcksXd" },
            { text: "工业厂房", url: "/list?type=uKm6eDiJoYrAZPGxD" },
            { text: "科技创新", url: "/list?type=FA8Xojtg4yTxsd7iN" },
            { text: "水利生态", url: "/list?type=n84tasiTD7tZQBhyo" },
            { text: "教育文旅", url: "/list?type=aGfjy38WcauxhSZiZ" },
            { text: "市政公用", url: "/list?type=LSMZ4ZP532ofzGhLN" },
            { text: "景观绿化", url: "/list?type=uSjku6koPfkKKHqAg" },
            { text: "其他项目", url: "/list?type=APpWvgSZmTHK7jre2" },
          ],
        },
        {
          title: "凯达招商",
          itemList: [
            { text: "新能源汽车产业园", url: "/list?type=8h9f8jod6TmGv7avi" },
            { text: "新技术产业园", url: "/list?type=bP4xpgw5GvN5Li8uB" },
            { text: "青年人才服务园", url: "/list?type=KYeQPFqhKjTJZFqgG" },
            { text: "人力资源产业园", url: "/list?type=gJywirRF6jgZBFMgc" },
            { text: "了解更多", url: "/list?type=XKWc77iMypXKFhgMg" },
          ],
        },
        {
          title: "凯达招聘",
          itemList: [
            { text: "人才理念", url: "/recruit/rcln?type=rcln" },
            { text: "加入凯达", url: "/recruit/jrkd?type=jrkd" },
            { text: "创新凯达", url: "/recruit/cxkd?type=cxkd" },
            { text: "成长凯达", url: "/recruit/czkd?type=czkd" },
            { text: "幸福凯达", url: "/recruit/xfkd?type=xfkd" },
          ],
        },
        {
          title: "党建工作",
          itemList: [
            { text: "党建动态", url: "/list?type=YcY7PMyvthmQndhZY" },
            { text: "反腐倡廉", url: "/list?type=sXMW3G43NR6ZchSFL" },
            { text: "专题活动", url: "/list?type=6tsXCPh3t3r7i8KyK" },
          ],
        },
        {
          title: "凯达云",
          itemList: [
            {
              text: "项目管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "合同管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "档案管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "印信管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "人事管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "会议管理",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "凯达云旅",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
            {
              text: "企业钱包",
              url: "https://accounts.icloudportal.com/oauth2?client_id=icloudportal&redirect_uri=https%3A%2F%2Ficloudportal.com%2Foauth&response_type=code&scope=SCOPE&state=dashboard",
            },
          ],
        },
        {
          title: "友情链接",
          itemList: [
            { text: "政府网站", url: "/links?type=hKk9rEqQFtNBxaZG8" },
            { text: "党建人社", url: "/links?type=rcwkwWN3q9zcEbTHk" },
            { text: "平台企业", url: "/links?type=Xzbq5SeJrnyd9zDhq" },
            { text: "建工企业", url: "/links?type=bhmaeNxJkccYvPLAs" },
            { text: "财经金融", url: "/links?type=h5KWSqALLfJcj6Shu" },
            { text: "咨询机构", url: "/links?type=PQM7LsbN8yuaD5PAn" },
            { text: "合作单位", url: "/links?type=wJcqjd54mDnBGRK5g" },
            { text: "合作高校", url: "/links?type=K5KA3fFujFGMiF8ds" },
          ],
        },
      ],
      language: "繁体中文",
      simple: true,
    };
  },
  mounted() {
    this.isShow = -1;
    // this.language = "繁體中文2";
    // localStorage.setItem("lang", "zh_Hans");
    localStorage.getItem("lang");
  },
  methods: {
    switchLanguage(val) {
      this.lang = val;
      this.simple = !this.simple;
      if (this.simple) {
        this.language = "繁体中文";
        localStorage.setItem("lang", "zh_Hans");
      } else {
        this.language = "簡體中文";
        localStorage.setItem("lang", "zh_Hant");
      }
      this.$loadLanguage(localStorage.getItem("lang"));
    },
    drawerShow() {
      this.show = !this.show;
      // console.log(document.documentElement.clientHeight);
    },
    drawerHidden() {
      this.show = !this.show;
    },
    submenuShow(index) {
      this.isShow = index;
    },
    blankGo(url) {
      window.open(url, "_blank");
    },
    selfGo(url) {
      window.open(url, "_self");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.menu {
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  padding: 25px 0;
  .link-menu {
    display: flex;
    li {
      margin-right: 30px;
      cursor: pointer;
    }
  }
  .drawer {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    background: #fff;
    width: 800px;
    .drawer-menu {
      position: relative;
      display: flex;
      flex-direction: column;
      background: rgba(109, 171, 226, 0.9);
      width: 280px;
      line-height: 50px;
      padding: 80px 60px;
      li {
        color: #fff;
        border-bottom: 1px solid #fff;
        cursor: pointer;
        a {
          display: block;
        }
      }
      li:hover {
        color: #074780;
        border-color: #074780;
      }
      li:hover::before {
        float: right;
        content: ">";
        color: #074780;
      }
      .drawer-submenu {
        position: absolute;
        left: 400px;
        top: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 280px;
        padding: 80px 60px;
        li {
          color: #333;
        }
        li:hover {
          border-color: #ccc;
        }
        li:hover::before {
          color: #ccc;
        }
      }
    }
    .drawer-hidden {
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 999;
    }
  }
}
.iconfont {
  display: block;
  width: 50px;
  font-size: 50px;
  color: #444;
  line-height: 50px;
  cursor: pointer;
}
</style>
