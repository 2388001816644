import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../Main.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { title: " " },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/main",
    component: Main,
    children: [
      {
        path: "/list",
        name: "List",
        meta: { title: " " },
        component: () =>
          import(/* webpackChunkName: "List" */ "../views/List.vue"),
      },
      {
        path: "/detail",
        name: "Detail",
        meta: { title: " " },
        component: () =>
          import(/* webpackChunkName: "Detail" */ "../views/Detail.vue"),
      },
      {
        path: "/links",
        name: "Links",
        meta: { title: " " },
        component: () =>
          import(/* webpackChunkName: "Links" */ "../views/Links.vue"),
      },
      {
        path: "/about/qyjj",
        name: "qyjj",
        meta: { title: "企业简介 - " },
        component: () =>
          import(/* webpackChunkName: "qyjj" */ "../views/About/qyjj.vue"),
      },
      {
        path: "/about/dszzc",
        name: "dszzc",
        meta: { title: "董事长致辞 - " },
        component: () =>
          import(/* webpackChunkName: "dszzc" */ "../views/About/dszzc.vue"),
      },
      {
        path: "/about/zzjg",
        name: "zzjg",
        meta: { title: "组织结构 - " },
        component: () =>
          import(/* webpackChunkName: "zzjg" */ "../views/About/zzjg.vue"),
      },
      {
        path: "/about/qywh",
        name: "qywh",
        meta: { title: "企业文化 - " },
        component: () =>
          import(/* webpackChunkName: "qywh" */ "../views/About/qywh.vue"),
      },
      {
        path: "/recruit/rcln",
        name: "rcln",
        meta: { title: "人才理念 - " },
        component: () =>
          import(/* webpackChunkName: "rcln" */ "../views/Recruit/rcln.vue"),
      },
      {
        path: "/recruit/jrkd",
        name: "jrkd",
        meta: { title: "加入凯达 - " },
        component: () =>
          import(/* webpackChunkName: "jrkd" */ "../views/Recruit/jrkd.vue"),
      },
      {
        path: "/recruit/cxkd",
        name: "cxkd",
        meta: { title: "创新凯达 - " },
        component: () =>
          import(/* webpackChunkName: "cxkd" */ "../views/Recruit/cxkd.vue"),
      },
      {
        path: "/recruit/czkd",
        name: "czkd",
        meta: { title: "成长凯达 - " },
        component: () =>
          import(/* webpackChunkName: "czkd" */ "../views/Recruit/czkd.vue"),
      },
      {
        path: "/recruit/xfkd",
        name: "xfkd",
        meta: { title: "幸福凯达 - " },
        component: () =>
          import(/* webpackChunkName: "xfkd" */ "../views/Recruit/xfkd.vue"),
      },
      {
        path: "/pages/lxwm",
        name: "lxwm",
        meta: { title: "联系我们 - " },
        component: () =>
          import(/* webpackChunkName: "lxwm" */ "../views/Pages/lxwm.vue"),
      },
      {
        path: "/pages/wzsm",
        name: "wzsm",
        meta: { title: "网站声明 - " },
        component: () =>
          import(/* webpackChunkName: "wzsm" */ "../views/Pages/wzsm.vue"),
      },
      {
        path: "/pages/cjwt",
        name: "cjwt",
        meta: { title: "常见问题 - " },
        component: () =>
          import(/* webpackChunkName: "cjwt" */ "../views/Pages/cjwt.vue"),
      },
      {
        path: "/pages/lyb",
        name: "lyb",
        meta: { title: "留言板 - " },
        component: () =>
          import(/* webpackChunkName: "lyb" */ "../views/Pages/lyb.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
