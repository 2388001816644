<template>
  <div class="header">
    <div class="fixed">
      <div class="wrapper">
        <div class="logo">
          <router-link to="/">长春凯达</router-link>
        </div>
        <Menu />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./Menu.vue";
export default {
  name: "Header",
  components: { Menu },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.header {
  height: 100px;
  .fixed {
    position: fixed;
    top: 0;
    left: 0;
    background: #dfdfdf;
    width: 100%;
    height: 100px;
    z-index: 9;
    .wrapper {
      display: flex;
      justify-content: space-between;
      .logo {
        padding: 30px 0;
        a {
          display: block;
          background: url(../assets/images/logo.png) no-repeat 50% 50%;
          background-size: 145px 40px;
          width: 145px;
          height: 40px;
          text-indent: -9999px;
        }
      }
    }
  }
}
</style>
